import React from "react";

import "./BookLandingPage.css";
import EBoookThankYou from "../../assets/ebook-thank-you.jpg";

const BookLandingPage = () => {
  return (
    <section className="book">
      <section className="book__title">
        Download “Not The Holy Grail of Trading”
      </section>
      <section className="book__image">
        <img src={EBoookThankYou} />
      </section>
      <section className="book__body">
        <p>Thank you for subscribing!</p>
        <p>Click on the link below to gain full access to the book…</p>
        <p>
          <a
            href="https://drive.google.com/file/d/1OR_cc_Ewl39SijtFZ6j2papYoeOd-j0F/view?usp=sharing"
            target="_blank"
          >
            Download Not the Holy Grail of Trading
          </a>
        </p>
        <p>
          For more inquiries, we are a stone throw away, you can reach out to us
          on
          <a href="mailto:wolstreetfinance@wolstreetfinance.com">
            wolstreetfinance@wolstreetfinance.com
          </a>
          , we would be glad to be of assistance.
        </p>
        {/* <p>
          Also note, we have a free
          <a href="https://t.me/wolstreetfinance" target="_blank">
            Telegram
          </a>{" "}
          community where we share free trading ideas, personal finance hacks
          and life hacks.
        </p> */}
        <p className="list">
          {/* Here’s what to expect on the free telegram channel… */}
          <ul className="unordered__list">
            <li>Easy to execute trading ideas.</li>
            <li>
              Exclusive access to my blogposts when they drop, and you will also
              be the first person to be notified when I drop any video on
              <a
                href="https://youtube.com/@WolstreetFinance?si=g9cD5rur4KS_pdqf"
                target="_blank"
              >
                Youtube
              </a>
            </li>
          </ul>
        </p>
        {/* <p>
          You can click on the link below to join now... Here’s the link ==&gt;
          <a href="https://t.me/wolstreetfinance" target="_blank">
            https://t.me/wolstreetfinance
          </a>
        </p>
        <p>It's FREE!</p> */}
        <p>
          If you also don’t want to miss out on how I analyze the market with
          precision and depth, consider subscribing to my YouTube channel.
        </p>
        <p>
          You can click on the link below to SUBSCRIBE to my youtube channel...
          Here's the link ==&gt;
          <a
            href="https://youtube.com/@WolstreetFinance?si=g9cD5rur4KS_pdqf"
            target="_blank"
          >
            https://youtube.com/@WolstreetFinance?si=g9cD5rur4KS_pdqf
          </a>
        </p>
        <p>It's FREE!</p>
      </section>
    </section>
  );
};

export default BookLandingPage;
