import React from "react";

import "./PaymentOption.css";

const PaymentOption = ({ onSelectCurrency, onCancel }) => {
  const handleCurrencySelection = (currency) => {
    onSelectCurrency(currency);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="payment__options">
      <button className="payment__options__proceed" onClick={() => handleCurrencySelection("USD")}>USD</button>
      <button className="payment__options__proceed" onClick={() => handleCurrencySelection("NGN")}>NGN</button>
      <button className="payment__options__cancel" onClick={handleCancel}>Go Back</button>
    </div>
  );
};

export default PaymentOption;
